@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

#root {
    background-color: #F4F5F5;
}

.mapboxgl-control-container {
    display: none;
}

.mapboxgl-marker {
	position: absolute;
	top: 0;
	left: 0;
	will-change: transform;
	opacity: 1;
	transition: opacity .2s;
    background: white;
    border-radius: 50%;
    border: #058828 solid 2px
}

#no-scrollbar::-webkit-scrollbar {
    display: none;
}

#loading_guy {
    border: solid 4px;
    border-color:#058828 #058828 transparent transparent;
}